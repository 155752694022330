import React from "react";
import { Helmet } from "react-helmet";

export const EdTechJsonLd = () => {
  const mainjson = {
    "@context": "https://schema.org",
    "@type": "ProfessionalService",
    name: "We make learning fun and engaging",
    description:
      "Adam Fard UX Studio is specialized in Edtech Design. We help you to create and scale your educational app and platform.",
    disambiguatingDescription:
      "Edtech Design is a specialty of Adam Fard UX Studio. We will assist you in developing and scaling your educational app and website.",
    url: "https://adamfard.com/edtech-design",
    openingHours: ["Mon-Fri: 24/7"],
    telephone: "no",
    foundingDate: "2016",
    paymentAccepted: [
      "Visa",
      "Cash",
      "Master Card",
      "Credit Card",
      "Cash",
      "American Express"
    ],
    priceRange: "$$",
    image: {
      "@type": "ImageObject",
      url:
        "https://adamfard.com/static/2bf104f6b91a675fc622caf7f9bd6b77/b2c2d/edtech-design-ux.webp",
      height: 800,
      width: 1200
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "Neue Schönhauser Straße 3",
      addressLocality: "Berlin",
      addressRegion: "BE",
      postalCode: "10178",
      areaServed: "Germany"
    },
    award: [
      "Top Empathetic 2020",
      "Best UX design in Berlin",
      "2020 Best User testing",
      "Top UX research",
      "Top UX Agency & B2B company",
      "Top Web Design Company",
      "Featured UX Studio"
    ],
    brand: [
      "UX designer",
      "User testing",
      "Competitive analysis",
      "Design App",
      "What is ux design",
      "What is agile",
      "Redesigning",
      "UX research",
      "Technology in education",
      "Information architecture",
      "Dashboard design",
      "UX writing",
      "Heuristic evaluation",
      "Organizing workshops",
      "How to app design",
      "Product idea",
      "Design process UX",
      "Lean UX",
      "Dashboards UI",
      "UI dashboard",
      "Design libraries",
      "Heuristics analysis",
      "Prototypes design",
      "UX strategy",
      "Information architecture UX",
      "UI UX design agency"
    ],
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "customer support",
      email: "contact@adamfard.com",
      availableLanguage: "en"
    },
    areaServed: ["Germany", "USA"],
    knowsAbout: [
      "UX design for startups",
      "UX design agency",
      "UX design blog",
      "Edtech startups",
      "Agile design",
      "App design process",
      "App monetization",
      "App redesign",
      "Future of augmented reality",
      "Technology and education",
      "UX research methods",
      "Value of ux design",
      "How to choose brand colors",
      "Best chatbot",
      "Competitive market analysis",
      "What is user testing",
      "Contextual inquiry UX",
      "Customer experience strategy",
      "Customer journey template",
      "Dashboard ui design",
      "UX design system",
      "Design thinking process",
      "Design thinking workshop",
      "Design validation",
      "Design bias",
      "Design ops",
      "User experience testing",
      "UX best practices",
      "Edtech trends",
      "App designer education",
      "Design thinking empathy",
      "Enterprise software UX design",
      "Fintech App",
      "Fintech UX Design",
      "UX Gamification",
      "Goodfirms",
      "App development mistakes",
      "Heuristic evaluation",
      "Hire a UX Designer",
      "Product validation",
      "New business startup ideas",
      "User behavior",
      "Information architecture UX",
      "Lean UX design",
      "Usability Metrics",
      "Fintech trends",
      "Persuasive Design",
      "Pitch Decks",
      "Product prototype",
      "UX prototyping",
      "Quibi app review",
      "Racial discrimination in the workplace",
      "Remote team building",
      "Retention Rate",
      "Stakeholder interview questions",
      "Funding strategies for startups",
      "App stickiness",
      "Design style guide",
      "Trello review",
      "Understanding User Behavior",
      "Usability testing methods",
      "Usability testing questions",
      "Usability testing tools",
      "User research methods",
      "SaaS Monetization",
      "UX and SEO",
      "UX architect",
      "UX Audit",
      "What Does a UX Designer Do",
      "Onboarding UX checklist",
      "UX strategy",
      "UX testing",
      "UI UX tools",
      "UX workshop",
      "UX writing",
      "Design Validation",
      "Website Redesign",
      "Purpose of wireframes",
      "Business Development Manager",
      "Best UX Design Resources",
      "Customer Experience Strategy",
      "Heuristic evaluation checklist",
      "How to Launch a Digital Product",
      "Startup Pitch Deck",
      "Prototyping in UX",
      "Client questionnaire",
      "Usability Testing Checklist",
      "UX designer interview questions",
      "Create an educational App",
      "Fintech startups",
      "Free strategy session",
      "Marketing specialist",
      "Communications as a Service Platform",
      "City Ride App",
      "B2B payment platform",
      "App testing platform",
      "Morecast Weather App",
      "SaaS ux design",
      "UI ux designer job description",
      "UX Design Process",
      "UX Design Projects"
    ],
    department: "UX and UI Design Studio",
    parentOrganization: "Adarm Fard",
    knowsLanguage: "en-USA",
    logo:
      "https://www.datocms-assets.com/16499/1612210672-cover-usability-testing.png",
    sameAs: [
      "https://www.linkedin.com/company/adam-fard/",
      "https://twitter.com/adamfard_",
      "https://medium.com/@adam.fard/",
      "https://dribbble.com/adamfardstudio",
      "https://www.behance.net/AdamFardStudio/"
    ]
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/edtech-design/#Webpage",
    url: "https://adamfard.com/edtech-design",
    headline: "Edtech Design: We make learning fun and engaging",
    primaryImageOfPage: [
      "https://adamfard.com/static/2bf104f6b91a675fc622caf7f9bd6b77/b2c2d/edtech-design-ux.webp"
    ],
    lastReviewed: "2021/01/16",
    relatedLink: ["https://en.wikipedia.org/wiki/User_interface"],
    significantLink: "https://adamfard.com/contact-us",
    description:
      "Adam Fard UX Studio is specialized in Edtech Design. We help you to create and scale your educational app and platform.",
    image:
      "https://adamfard.com/static/2bf104f6b91a675fc622caf7f9bd6b77/b2c2d/edtech-design-ux.webp",
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };
  const breadCrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "2",
    description:
      "Adam Fard UX Studio is specialized in Edtech Design. We help you to create and scale your educational app and platform.",
    disambiguatingDescription:
      "Edtech Design is a specialty of Adam Fard UX Studio. We will assist you in developing and scaling your educational app and website.",
    mainEntityOfPage: "https://adamfard.com/edtech-design",
    image:
      "https://adamfard.com/static/2bf104f6b91a675fc622caf7f9bd6b77/b2c2d/edtech-design-ux.webp",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://adamfard.com/edtech-design",
          name: "Edtech Design"
        }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(mainjson)}</script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadCrumbJson)}
      </script>
    </Helmet>
  );
};

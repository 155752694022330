import React from "react";
import { graphql, Link } from "gatsby";
import { PopupButton } from "react-calendly";
// import Slider from "react-slick";

import Layout from "../components/layout";

import useResizeScreen from "../utils/resize";

import Divider1 from "../images/dividers/divider-1.svg";
import SvgDivider2 from "../images/dividers/divider-2.inline.svg";
import IconHorizontal from "../images/icons/interface/horizontal.svg";
import IconUserNetwork from "../images/icons/theme/general/user.svg";
import IconSketch from "../images/icons/interface/sketch.svg";
import IconSearchAnalytics from "../images/icons/theme/general/search.svg";
import IconFlatten from "../images/icons/theme/design/flatten.svg";
import IconPatch from "../images/icons/theme/design/patch.svg";

import IconEdtech1 from "../images/icons/interface/edtech-1.svg";
import IconEdtech2 from "../images/icons/interface/edtech-2.svg";
import IconEdtech3 from "../images/icons/interface/edtech-3.svg";
import IconEdtech4 from "../images/icons/interface/edtech-4.svg";
import IconEdtech5 from "../images/icons/interface/edtech-5.svg";
import IconEdtech6 from "../images/icons/interface/edtech-6.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import { EdTechJsonLd } from "../components/jsonld/EdTechJsonLd";
//
// import imageFirstScreen from "../images/photos/edtech-design-ux.png";
// import imageSecondScreen from "../images/photos/landing-page-section-2.png";
// import imageThirdScreen from "../images/photos/landing-page-section-3.png";
// import imageSixthSection1 from "../images/photos/encourage_usage.png";
// import imageSixthSection2 from "../images/photos/landing-page-2-section-6-2.png";
// import imageSeventhSection1 from "../images/photos/landing-page-2-section-7-1.png";
// import imageSeventhSection2 from "../images/photos/landing-page-2-section-7-2.png";
// import imageSeventhSection3 from "../images/photos/landing-page-2-section-7-3.png";

const EdtechDesignPage = ({ data, location: { pathname } }) => {
  const { isMobile, isTablet } = useResizeScreen();

  return (
    <Layout
      meta={{
        seo: data.datoCmsPage.seo,
        path: pathname
      }}
      footerBgClass="bg-primary-3"
    >
      <EdTechJsonLd />
      <div className="landing-page-2">
        <SectionFirstScreen isMobile={isMobile} data={data} />
        <SectionSecondScreen isMobile={isMobile} data={data} />
        <SectionThirdScreen isMobile={isMobile} data={data} />
        <SectionFourthScreen isMobile={isMobile} />
        <SectionFifthScreen />
        <SectionSixthScreen isMobile={isMobile} data={data} />
        <SectionSeventhScreen isMobile={isMobile} data={data} />
        <SectionEighthScreen />
        {/* <SectionNinthScreen  /> */}
      </div>
    </Layout>
  );
};

const SectionFirstScreen = ({ isMobile, data }) => {
  const imageFirstScreen = data.imageFirstScreen.childImageSharp.gatsbyImageData;

  return (
    <section className="section-first bg-primary has-divider text-light o-hidden">
      <div className="container layer-2">
        <div className="row">
          {!isMobile && (
            <div className="col-md-6 col-lg-7 image">
              <GatsbyImage alt={"Edtech design"} image={imageFirstScreen} loading="eager"/>
            </div>
          )}
          <div className="col-md-6 col-lg-5">
            <h1 className="display-5 pb-3">
              Edtech Design:
              <br />
              We make learning fun and engaging
              {/* {!isMobile
                  ? `Edtech Design: Learning Can Be Hard Don’t Let Bad UX Design Make It Harder`
                  : `Edtech Design: Don’t Let Bad UX Design Make Learning Harder `} */}
            </h1>
            <p className="lead pb-5">
              {!isMobile
                ? `We help EdTech companies increase engagement, retention, and usage for their educational
                apps and platform through great Edtech design.`
                : `We help EdTech companies increase engagement, retention, and usage for their educational platforms`}
            </p>
            <Link to="/free-strategy-session" className="btn btn-lg btn-white">
                            Get Free Strategy Session
                        </Link>

            {/* <PopupButton
              className="btn btn-lg btn-white"
              color="#00a2ff"
              text="Schedule a Free UX Session"
              textColor="#ffffff"
              url="https://calendly.com/adamfard/30min-ux-ws"
              utm={{
                utmCampaign: "Calendly Link Integration",
                utmMedium: "Organic",
                utmSource: "Edtech Design"
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className="divider">
        <SvgDivider2 />
      </div>
    </section>
  );
};

const SectionSecondScreen = ({ isMobile, data }) => {
  const imageFirstScreen = data.imageFirstScreen.childImageSharp.gatsbyImageData;
  const imageSecondScreen = data.imageSecondScreen.childImageSharp.gatsbyImageData;

  return (
    <section className="second-section">
      <div className="container">
        <div className="row">
          {isMobile && (
            <div className="col-12 pb-4">
              <GatsbyImage image={imageFirstScreen} alt="" loading={"lazy"} />
            </div>
          )}
          <div className="col-md-6">
            <div className="content">
              <h3 className="h1">Get a Grip in a Competitive Industry</h3>
              <p className="lead">
                Creating an excellent user experience through design is the only
                way to reach out to more customers while improving retention and
                usage.
              </p>
              <p className="lead">
                What makes us stand out is that we offer unique edtech design
                solutions based on your needs and those of your customers.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <GatsbyImage image={imageSecondScreen} alt="" loading={"lazy"}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionThirdScreen = ({ isMobile, data }) => {
  const imageThirdScreen = data.imageThirdScreen.childImageSharp.gatsbyImageData;

  return (
    <section className="third-section has-divider">
      <div className="divider">
        <Divider1 />
      </div>

      <div className="container">
        <div className="row">
          {!isMobile && (
            <div className="col-md-6 col-lg-7">
              <GatsbyImage image={imageThirdScreen} alt="" loading={"lazy"} />
            </div>
          )}
          <div className="col-md-6 col-lg-5">
            <div className="content">
              <h3 className="h1">Guaranteeing a great experience</h3>
              <p className="lead">
                Learning can be hard (and sometimes boring). We employ UX design
                to make sure your platforms are fun, simple, and stress-free,
                guaranteeing a great experience for your students and rapid
                growth for your business.
              </p>
              <div>
                <Link to="/free-strategy-session" className="btn btn-lg btn-primary mt-3">
                                    Get Free Strategy Session
                                </Link>

                {/* <PopupButton
                  className="btn btn-lg btn-primary mt-3"
                  color="#00a2ff"
                  text="Schedule a Free UX Session"
                  textColor="#ffffff"
                  url="https://calendly.com/adamfard/30min-ux-ws"
                  utm={{
                    utmCampaign: "Calendly Link Integration",
                    utmMedium: "Organic",
                    utmSource: "Edtech Design"
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionFourthScreen = ({ isMobile }) => {
  const settings = {
    autoplay: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1750,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true
  };

  return (
    <section className="fourth-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="content">
              <h3 className="h1">Instant Edu Magic</h3>
              <p className="lead">
                We don’t always need tech to learn. But when we embrace and use
                it as an aid for teaching, the results are magical:
              </p>
            </div>
          </div>
        </div>

        <div className="row cards">
          <div className="col-md-6 col-lg-4">
            <div className="card card--common card-body">
              <div className="top">
                <div>
                  <IconEdtech1 />
                </div>
                <h5 className="h5">Instant Improvement</h5>
              </div>
              <div className="bottom">
                <p>
                  Immersion and gamification are just a few examples of turning
                  boring subjects into fun experiences.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card card--common card-body">
              <div className="top">
                <div>
                  <IconEdtech2 />
                </div>
                <h5 className="h5">Improved Delivery of Education</h5>
              </div>
              <div className="bottom">
                <p>
                  With EdTech, education delivery can be optimized, saving money
                  and allowing for affordable education for everyone.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card card--common card-body">
              <div className="top">
                <div>
                  <IconEdtech3 />
                </div>
                <h5 className="h5">Personalized Learning</h5>
              </div>
              <div className="bottom">
                <p>
                  The old education system of treating students like products on
                  an assembly line. EdTech allows teachers to personalize
                  learning for each student.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card card--common card-body">
              <div className="top">
                <div>
                  <IconEdtech4 />
                </div>
                <h5 className="h5">Learn Your Own Pace, available 24/7</h5>
              </div>
              <div className="bottom">
                <p>
                  Learners can learn just about anywhere and at any time they
                  want. They can go as fast or as slow as they want. They can
                  even go back to previous lessons and get support online.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card card--common card-body">
              <div className="top">
                <div>
                  <IconEdtech5 />
                </div>
                <h5 className="h5">Preparing Students for the Modern World</h5>
              </div>
              <div className="bottom">
                <p>
                  With EdTech, education delivery can be optimized, saving money
                  and allowing for affordable education for everyone.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="card card--common card-body">
              <div className="top">
                <div>
                  <IconEdtech6 />
                </div>
                <h5 className="h5">Easy Performance Monitoring</h5>
              </div>
              <div className="bottom">
                <p>
                  Programs are designed to give teachers data on how students
                  are faring, saving time and effort
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SectionFifthScreen = () => (
  <section className="fifth-section bg-primary has-divider">
    <div className="divider">
      <Divider1 />
    </div>

    <div className="container text-light">
      <div className="row">
        <div className="col-12">
          <h3 className="h1">
            How are we different from other UX design agencies?
          </h3>
        </div>
      </div>
      <div className="row blocks">
        <div className="col-md-6 col-lg-3">
          <div className="">
            <div>
              <IconHorizontal className="injected-svg icon icon-md bg-white" />
            </div>
            <p className="h5">Deadlines</p>
            <p className="lead">
              We adhere to deadlines without needing you to micromanage.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="">
            <div>
              <IconUserNetwork className="injected-svg icon icon-md bg-white" />
            </div>
            <p className="h5">Data-driven</p>
            <p className="lead">
              Our work is always backed by a 100% human and analytical
              understanding.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="">
            <div>
              <IconSketch className="injected-svg icon icon-md bg-white" />
            </div>
            <p className="h5">Communication</p>
            <p className="lead">
              We provide clear communication throughout the duration of the
              project.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="">
            <div>
              <IconSearchAnalytics className="injected-svg icon icon-md bg-white" />
            </div>
            <p className="h5">Behavior Design</p>
            <p className="lead">
              We gather knowledge on user behavior, making research process
              effective.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionSixthScreen = ({ isMobile, data }) => {
  const imageSixthSection1 = data.imageSixthSection1.childImageSharp.gatsbyImageData;
  const imageSixthSection2 = data.imageSixthSection2.childImageSharp.gatsbyImageData;

  return <>
    <section className="sixth-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="row">
              <div className="col-12">
                <h3 className="h1">Famous Isn’t Enough Anymore</h3>
                <p className="lead pb-5">
                  {!isMobile
                    ? `To captivate students, EdTech has to do more than be a container for learning. It
                  has to captivate and engage learners in the same way as the consumer tech they are
                  used to.`
                    : `EdTech captivates and engages learners in the same way as the consumer tech they are used to.`}
                </p>
              </div>

              <div className="col-md-6">
                <div className="image shifted-image">
                  <GatsbyImage image={imageSixthSection1} alt="Encourage usage" loading={"lazy"}/>
                </div>
                <h5 className="h3 mt-md-4">Encourage usage</h5>
                <p className="lead">
                  Being a leader or famous in the education sector isn’t
                  enough anymore. Your website and apps need to encourage
                  usage. This is only possible with excellent UX design.
                </p>
              </div>
              <div className="col-md-6">
                <GatsbyImage
                  image={imageSixthSection2}
                  className="shifted-image"
                  alt="Deep Users Research" loading={"lazy"}/>
                <h5 className="h3 mt-md-4">Deep Users Research</h5>
                <p className="lead">
                  We use deep research to understand how students use the
                  programs we create. This allows us to take biases out of the
                  design process and give students what they truly need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="cta-section">
      <div className="container wrapper">
        <div>
          <Link to="/free-strategy-session" className="btn btn-lg btn-white">
                          Get Free Strategy Session
                      </Link>

          {/* <PopupButton
            className="btn btn-lg btn-white my-4"
            color="#00a2ff"
            text="Schedule a Free UX Session"
            textColor="#ffffff"
            url="https://calendly.com/adamfard/30min-ux-ws"
            utm={{
              utmCampaign: "Calendly Link Integration",
              utmMedium: "Organic",
              utmSource: "Edtech Design"
            }}
          /> */}
        </div>
      </div>
    </section>
  </>;
};

const SectionSeventhScreen = ({ isMobile, data }) => {
  const imageSeventhSection1 = data.imageSeventhSection1.childImageSharp.gatsbyImageData;
  const imageSeventhSection2 = data.imageSeventhSection2.childImageSharp.gatsbyImageData;
  const imageSeventhSection3 = data.imageSeventhSection3.childImageSharp.gatsbyImageData;

  return (
    <section className="seventh-section">
      <div className="container">
        <h3 className="h1 pb-4">Our Expertise</h3>

        <div className="row">
          {!isMobile && (
            <div className="col-md-6 image">
              <GatsbyImage image={imageSeventhSection1} alt="" loading={"lazy"}/>
            </div>
          )}
          <div className="col-md-6">
            <ul className="list">
              <li>
                <div>
                  <IconFlatten className="blue" />
                </div>
                <div>
                  <p className="h4">Early child learning</p>
                  <p className="lead">
                    We design platforms that encourage children to learn through
                    play and interaction.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <IconFlatten className="yellow" />
                </div>
                <div>
                  <p className="h4">E-learning</p>
                  <p className="lead">
                    We have vast experience in designing systems meant to help
                    students learn online.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <IconFlatten className="teal" />
                </div>
                <div>
                  <p className="h4">LMS</p>
                  <p className="lead">
                    Our applications help teachers create, deliver content, and
                    monitor student performance.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          {isMobile && (
            <div className="col-12 text-center mb-5">
              <GatsbyImage image={imageSeventhSection3} alt="" loading={"lazy"}/>
            </div>
          )}
        </div>

        <div className="row second-row">
          <div className="col-md-6">
            <ul className="list">
              <li>
                <div>
                  <IconFlatten className="red" />
                </div>
                <div>
                  <p className="h4">Gamification</p>
                  <p className="lead">
                    We use game mechanisms and rewards to design learning
                    experiences.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <IconFlatten className="green" />
                </div>
                <div>
                  <p className="h4">Data Visualization</p>
                  <p className="lead">
                    We design programs to help you visualize your data.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <IconFlatten className="orange" />
                </div>
                <div>
                  <p className="h4">VR / AR</p>
                  <p className="lead">
                    Engaging students with virtual and augmented reality, using
                    tech to mimic real-life settings.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          {!isMobile && (
            <div className="col-md-6 image">
              <GatsbyImage image={imageSeventhSection2} alt="" loading={"lazy"}/>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const SectionEighthScreen = () => (
  <section className="eighth-section bg-primary-alt has-divider">
    <div className="divider">
      <Divider1 />
    </div>

    <div className="container">
      <div className="row">
        <div className="col-lg-10 mx-auto">
          <div className="row">
            <div className="col-12">
              <h3 className="h1">You Win When Your Users Win </h3>
            </div>
            <div className="col-md-4">
              <div className="block">
                <div>
                  <IconPatch className="blue" />
                </div>
                <p className="lead">
                  <strong>Retention</strong>
                </p>
                <p className="lead">
                  Happy users mean better customer retention and less marketing
                  expenditure.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="block">
                <div>
                  <IconPatch className="teal" />
                </div>
                <p className="lead">
                  <strong>Usability & Ratings</strong>
                </p>
                <p className="lead">
                  Better usability means fewer bad ratings and reviews (so more
                  new customers for you through referrals).
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="block">
                <div>
                  <IconPatch className="red" />
                </div>
                <p className="lead">
                  <strong>Usage & Funding</strong>
                </p>
                <p className="lead">
                  High app/website usage statistics means happy shareholders and
                  high chances of getting more funding.
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="text-center mt-0 mt-md-4">
                <Link to="/free-strategy-session" className="btn btn-lg btn-primary mt-2 mt-md-4">
                                    Get Free Strategy Session
                                </Link>

                {/* <PopupButton
                  className="btn btn-lg btn-primary mt-2 mt-md-4"
                  color="#00a2ff"
                  text="Schedule a Free UX Session"
                  textColor="#ffffff"
                  url="https://calendly.com/adamfard/30min-ux-ws"
                  utm={{
                    utmCampaign: "Calendly Link Integration",
                    utmMedium: "Organic",
                    utmSource: "Edtech Design"
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default EdtechDesignPage;

export const query = graphql`query EdtechDesignPage {
  imageFirstScreen: file(relativePath: {eq: "photos/edtech-design-ux.png"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  imageSecondScreen: file(relativePath: {eq: "photos/educational-app-Image.png"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  imageThirdScreen: file(relativePath: {eq: "photos/edtech-img.png"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  imageSixthSection1: file(relativePath: {eq: "photos/encourage_usage.png"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  imageSixthSection2: file(
    relativePath: {eq: "photos/landing-page-2-section-6-2.png"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  imageSeventhSection1: file(
    relativePath: {eq: "photos/landing-page-2-section-7-1.png"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  imageSeventhSection2: file(
    relativePath: {eq: "photos/landing-page-2-section-7-2.png"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  imageSeventhSection3: file(
    relativePath: {eq: "photos/landing-page-2-section-7-3.png"}
  ) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, width: 800)
    }
  }
  datoCmsPage(slug: {eq: "edtech-design"}) {
    seo {
      title
      description
      twitterCard
      image {
        url
      }
    }
  }
}
`;
